import React, { Component } from "react";
import Web3 from "web3";
import { Button } from "react-bootstrap";
import { AccountInfoContext } from '../Context/AccountInfo'
import EvosBurnContract  from "../contracts/JcodeBurnContract.json";
import EvosContract from "../contracts/ERC721AABI.json"

class Connect extends Component {
  
  static contextType =  AccountInfoContext
  
  componentDidMount = async () => {

    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      this.web3  = new Web3(window.web3.currentProvider);
    }else{
      var provider = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID1}`
      var web3Provider = new Web3.providers.HttpProvider(provider);
      this.web3 = new Web3(web3Provider);
    };
    this.context.updateAccountInfo({web3: this.web3})
    if(this.web3){
      await this.setNetwork();
      await this.getContractsInstances();
      if(window.ethereum || window.web3){
        await this.setAccount();
      }
      
    }
  }

  async getContractsInstances(){
    this.networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({networkId: this.networkId})

    this.EvosContractInstance = new this.web3.eth.Contract(
      EvosContract.abi,
      parseInt(process.env.REACT_APP_MAINNET_EVO_CONTRACT_ADDRESS) && process.env.REACT_APP_MAINNET_EVO_CONTRACT_ADDRESS
    )
    this.EvosBurnContractInstance = new this.web3.eth.Contract(
      EvosBurnContract.abi,
      parseInt(process.env.REACT_APP_MAINNET_CONTRACT_ADDRESS) && process.env.REACT_APP_MAINNET_CONTRACT_ADDRESS
    )
    this.context.updateAccountInfo({EvosContractInstance: this.EvosContractInstance})
    this.context.updateAccountInfo({EvosBurnContractInstance: this.EvosBurnContractInstance})
    this.getEvoBurnInfo();
  }

  async setAccount(){
    if(this.context.networkId !== null){
      let accounts = await this.web3.eth.getAccounts();
      await this.context.updateAccountInfo({account: accounts[0]});
      if(this.context.account) this.getAccountsData(accounts[0])
    }else{
      this.resetAccountData();
    }
  }

  resetAccountData(){
    this.context.updateAccountInfo({
      account: null,
    })
  }

  async setNetwork(){
    let networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({networkId: networkId})
  }

  async getAccountsData(account){
    if(this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK) ){
      this.context.updateAccountInfo({burnPerWindow: parseInt(await this.EvosBurnContractInstance.methods.getBurnByAddress(this.context.account).call())})
    }
  }

  async getEvoBurnInfo(){
    if(this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK) ){
      this.context.updateAccountInfo({burnOpened: await this.EvosBurnContractInstance.methods.burnOpened().call()})
      this.context.updateAccountInfo({burnLimitPerCollector: parseInt(await this.EvosBurnContractInstance.methods.burnLimitPerCollector().call())})
      this.context.updateAccountInfo({currentBurnWindow: parseInt(await this.EvosBurnContractInstance.methods.currentBurnWindow().call())})
      this.context.updateAccountInfo({burnAddress: parseInt(await this.EvosBurnContractInstance.methods.burnAddress().call())})
      this.context.updateAccountInfo({nftAddress: parseInt(await this.EvosBurnContractInstance.methods.nftAddress().call())})
      this.context.updateAccountInfo({totalBurnLimit: parseInt(await this.EvosBurnContractInstance.methods.totalBurnLimit().call())})
      this.context.updateAccountInfo({remainingBurnsForCurrentWindow: parseInt(await this.EvosBurnContractInstance.methods.getRemainingBurnsForCurrentWindow().call())})
    }
  }

  async connectWallet(){
    this.context.updateAccountInfo({transactionInProgress: true})
    try{
      window.ethereum.enable()
    }catch(error){
      console.log(error)
    }
    this.context.updateAccountInfo({transactionInProgress: false})
  }

  getAccountStr(account){
    let response = account.slice(0, 5) +  '...' + account.substring(account.length - 2)
    return response
  }

  renderUserInterface(){
    if(!window.ethereum && !window.web3){
      return <div>No wallet detected</div>
    } else if(!this.context.account){
      return <Button variant='secondary' onClick={() => this.connectWallet()}>Connect</Button>
    }else if(parseInt(this.context.networkId) !== parseInt(process.env.REACT_APP_MAINNET_NETWORK)){
      return <p>Please connect to {parseInt(this.context.networkId) === 1 ? "the GOERLI Network" : "Ethereum Mainnet"}</p>
    }else return null
  }

  render() {

    if(window.ethereum || window.web3){
      if(this.web3){
        window.ethereum.on('accountsChanged', async () => {
          await this.setAccount()
        })
        window.ethereum.on('networkChanged', async () => {
          await this.setNetwork()
          await this.setAccount();
        });
  
      }
    }
    return this.renderUserInterface()
  }
  
}


export default Connect;
