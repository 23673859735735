import React, {useState, useContext} from "react";
import {Row, Col, Form, Button, Spinner, Alert, Container} from 'react-bootstrap'
import { AccountInfoContext } from "../Context/AccountInfo";
import Connect from "./Connect";

import '../App.css'

function Home() {

    let accountInfo = useContext(AccountInfoContext)

    const [idToBurn, setIdToBurn] = useState('')
    const [alert, setAlert] = useState({active: false, content: null, variant: null})

    function renderAlert(){
        if(alert.active){
            return(
                <Alert variant={alert.variant} style={{maxWidth: '250px'}}>{alert.content}</Alert>
            )
        }

    }

    function displayAlert( message, variant){
        setAlert({active: true, content: message, variant: variant})
        setTimeout(function() { setAlert({active: false, content: null, variant: null}); }, 10000);
    }

    async function handleChange(event){
        setIdToBurn(event.target.value);
    }


    function renderEvoIdInput(){
        return(
            <Form id='id_form' style={{width: '200px'}} >
                <Form.Group  controlId="evoId">
                    <Form.Control 
                        type="number" 
                        min="0"
                        placeholder="ID of Evo to burn"
                        value={idToBurn}
                        onChange={(event) => handleChange(event)}/>
                </Form.Group>
            </Form>
        )
    }

    async function approveTransfer(){
        await accountInfo.EvosContractInstance.methods.setApprovalForAll(accountInfo.EvosBurnContractInstance._address, 'true').send({from: accountInfo.account})
        accountInfo.updateAccountInfo({isApprovedForAll: true});
    }

    async function burnEvo(){
        let owner = await accountInfo.EvosContractInstance.methods.ownerOf(idToBurn).call()
        if (owner.toLowerCase() === accountInfo.account.toLowerCase()){
            displayAlert(`Ownership verified, checking transfer authorization...`, 'secondary')
            let isApprovedForAll = await accountInfo.EvosContractInstance.methods.isApprovedForAll(accountInfo.account, accountInfo.EvosBurnContractInstance._address).call()
            if(!isApprovedForAll){
                displayAlert(`Contract not authorized for transfer, please approve`, 'secondary')
                try{
                    await approveTransfer()
                    displayAlert(`Approval successful`, 'success')
                }catch(e){
                    displayAlert(e.message, 'warning')
                }
            }
            displayAlert(`Contract authorized for transfer, please burn`, 'secondary')
            isApprovedForAll = await accountInfo.EvosContractInstance.methods.isApprovedForAll(accountInfo.account, accountInfo.EvosBurnContractInstance._address).call()
            if(isApprovedForAll){
                try{
                    await accountInfo.EvosBurnContractInstance.methods.burn(idToBurn).send({from: accountInfo.account});
                    displayAlert(`Burn successful!`, 'success')
                }catch(e){
                    displayAlert(e.message, 'warning')
                }
            }
        }else{
            displayAlert(`Not the owner of evo ${idToBurn}`, 'warning')
        }
    }

    function renderBurnButton(){
        return(
           <Button style={{width: '175px'}} onClick={burnEvo} variant='secondary'> Burn Evo</Button>
        )
    }

    function renderUI(){
        if(accountInfo.account){
            console.log(accountInfo.burnPerWindow)
            console.log(accountInfo.burnPerWindow)
            console.log(accountInfo.burnLimitPerCollector)
            if(accountInfo.burnOpened){
                if(accountInfo.totalBurnLimit === 0 || (accountInfo.remainingBurnsForCurrentWindow > 0)){
                    if(accountInfo.burnLimitPerCollector === 0 || accountInfo.burnPerWindow < accountInfo.burnLimitPerCollector){
                        return(
                            <Container fluid>
                                <Row className="justify-content-center mb-2">
                                    {renderEvoIdInput()}
                                </Row>
                                <Row className="justify-content-center">
                                    {renderBurnButton()}
                                </Row>
                                <Row className="justify-content-center my-2">
                                    {renderAlert()}
                                </Row>
                            </Container>
                        )
                    }else{
                        return <div>Maximum burn for this account reached</div>
                    }
                }else{
                    return(
                        <div>Maximum burn number reached</div>
                    )
                }
            }else{
                return <div>Burn window currently closed</div>
            }
        }else{
            return null
        }
    }

    return ( 
        <Container>
            {renderUI()}
            <Connect/>
        </Container>
     );
}

export default Home;


