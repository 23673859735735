import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Connect from './Components/Connect';
import Home from './Components/Home';
// import ConnexionStatus from './Components/ConnexionStatus';
import AccountInfoProvider from './Context/AccountInfo';
import ContractInfoProvider from './Context/ContractInfo';
import DropConfigProvider from './Context/DropConfig.js';
// import background from './images/background.png'
import './App.css'

function App() {
  return (
    <DropConfigProvider>
        <AccountInfoProvider>
          <ContractInfoProvider>
          <div className="background" >
            {/* <div className="background" style={{backgroundImage: `url(${background})`,}}> */}
              <div className="App d-flex align-items-center justify-content-center">
                <Container>
                    <Row id='App_row' className="d-flex align-items-center justify-content-center">
                      <Col className="d-flex align-items-center justify-content-center">
                        <Home/>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col className="">
                        <ConnexionStatus/>
                      </Col>
                    </Row> */}
                </Container>
              </div>
            </div>
          </ContractInfoProvider>
        </AccountInfoProvider>
      </DropConfigProvider>
  );
}

export default App;